import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { id } from '@swimlane/ngx-charts';
import * as moment from 'moment';
import { first } from 'rxjs-compat/operator/first';
import { LoginService } from 'src/app/core/services';
import { MazayaEcardAdminUpdate, MazayaEcardDetail } from 'src/app/shared/models/mazaya-plus/membership-details-model';
import { AdminService } from 'src/app/shared/services/admin.service';
import { NotificationsService } from 'src/app/shared/services/notifications.service';

interface Relation {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-home-mazaya-plus',
  templateUrl: './home-mazaya-plus.component.html',
  styleUrls: ['./home-mazaya-plus.component.scss']
})
export class HomeMazayaPlusComponent {
  @ViewChild('fileInput') fileInput: ElementRef;
  isEnableModal: boolean = false;
  email: string = "";
  cardNo: string = "";
  name: string = "";
  membershipDetails: MazayaEcardDetail[]
  myForm: FormGroup;
  profile_image: string = null;
  selectedImage: File | null = null;
  selectedCard: number;
  fileWithInfo: File;
  userCardView: boolean = false;
  maxDate: Date = this.calculateMaxDate();
  today: Date = new Date();
  searchEmail: string = "";
  selectedEmail: string = "";
  relations: Relation[] = [
    { value: 'Father', viewValue: 'Father' },
    { value: 'Mother', viewValue: 'Mother' },
    { value: 'Son', viewValue: 'Son' },
    { value: 'Daughter', viewValue: 'Daughter' },
    { value: 'Spouse', viewValue: 'Spouse' },
    { value: 'Other', viewValue: 'Other' },
  ];
  constructor(
    private formBuilder: FormBuilder,
    private _adminService: AdminService,
    private renderer: Renderer2,
    private loginService: LoginService,
    private _notificationService: NotificationsService,
    private _router: Router,
  ) {
    this.myForm = this.formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', Validators.email],
      dob: [''],
      relation: [''],

    });

  }

  calculateMaxDate(): Date {
    const today = new Date();
    const maxDate = new Date(today);
    maxDate.setFullYear(today.getFullYear() - 18);
    return maxDate;
  }

  onEdit(i) {
    this.selectedCard = i;
    this.isEnableModal = true
    const date = moment(this.membershipDetails[i].dob);
    const formattedDob = date.format('YYYY-MM-DD');

    this.profile_image = this.membershipDetails[i].profile_image;
    this.myForm = this.formBuilder.group({
      firstname: [this.membershipDetails[i].firstname, Validators.required],
      lastname: [this.membershipDetails[i].lastname, Validators.required],
      email: [this.membershipDetails[i].email, [Validators.required, Validators.email]],
      relation: [this.membershipDetails[i].relation, Validators.required],
      dob: [formattedDob, Validators.required]
    });
  }
  onSearch() {
    this.searchEmail = this.email.trim();
    const searchCardNo = this.cardNo.trim();
    const searchName = this.name.trim();
    if (this.searchEmail != "" || searchCardNo != "" || searchName != ""){
      const  rqBody:{username: string, cardnumber: string, name: string}=
      {
        username: this.searchEmail,
        cardnumber: searchCardNo,
        name: searchName
      }
      this._adminService.getMazayaCard(rqBody).subscribe((res) => {
        this.membershipDetails = res.data
      })
    } 
  }

  encodeString(value: string): string {
    return encodeURIComponent(value).replace(/%2B/g, '+');
  }

  onCloseEdit() {
    this.profile_image = null;
    this.isEnableModal = false;
    this.selectedCard = 0;
    this.selectedImage = null;

  }

  selectFile(): void {
    this.renderer.selectRootElement(this.fileInput.nativeElement).click();
  }
  onFileSelected(event: any): void {
    this.selectedImage = event.target.files[0];
    this.showPreview();

  }

  showPreview() {
    if (this.selectedImage) {

      const reader = new FileReader();
      reader.onload = (e) => {
        this.profile_image = reader.result as string;
      };
      reader.readAsDataURL(this.selectedImage);
    }
  }

  onUpdateDetails() {
    if (this.myForm.valid) {
      if (this.selectedImage != null) {
        this.loginService.saveTradeLicence(this.selectedImage).subscribe(
          (res) => {
            const formValues = this.myForm.value;
            const date = moment(formValues.dob);
            const formattedDob = date.format('YYYY-MM-DD');

            const request: MazayaEcardAdminUpdate = {
              id: this.membershipDetails[this.selectedCard].id,
              firstname: formValues.firstname.trim(),
              lastname: formValues.lastname.trim(),
              relation: formValues.relation,
              dob: formattedDob,
              email: formValues.email,
              profile_image: res.id,
              gender:"",
              phoneNumber:"",
              status: this.membershipDetails[this.selectedCard].status
            }


            this._adminService.updateEcard(request)
              .subscribe((res) => {

                if (res.data === "Success") {
                  this.onSearch();
                  this.onCloseEdit();
                  this._notificationService.success(
                    "Success",
                    "Successfully uploaded"
                  );
                }
              })
          }

        )
      } else {
        const formValues = this.myForm.value;
        const date = moment(formValues.dob);
        const formattedDob = date.format('YYYY-MM-DD');
        let imageId = null;
        const imageUrl = this.membershipDetails[this.selectedCard].profile_image
        if (imageUrl !== null) {
          const parts = imageUrl.split('documents/');

          if (parts.length > 1) {
            imageId = parts[1];

          } else {
            imageId = null
          }
        }
        const request: MazayaEcardAdminUpdate = {
          id: this.membershipDetails[this.selectedCard].id,
          firstname: formValues.firstname.trim(),
          lastname: formValues.lastname.trim(),
          relation: formValues.relation,
          dob: formattedDob,
          email: formValues.email,
          profile_image: imageId,
          gender:"",
          phoneNumber:"",
          status: this.membershipDetails[this.selectedCard].status
        }


        this._adminService.updateEcard(request)
          .subscribe((res) => {
            if (res.data === "Success") {
              this.onSearch();
              this.onCloseEdit();
              this._notificationService.success(
                "Success",
                "Successfully uploaded"
              );
            }
          })
      }

    } else {
      this._notificationService.error(
        "Error",
        "Please enter required fields"
      );
    }
  }
  onViewCardList(i) {
    this.selectedEmail = this.membershipDetails[i].email;
    console.log("~~~~~~", this.membershipDetails[i].email)
    this.userCardView = true;

  }
  goBack() {
    this.userCardView = false;
  }
  resendInvite(email:string) {
    this._adminService.resendInvite(email)
      .subscribe((res) => {
        this._notificationService.success(
          "Invited",
          "Successfully Invited"
        );
      })
  }
  onInputTrim(fieldName: string) {
    const control = this.myForm.get(fieldName);
    if (!control) return;

    const value = control.value;
    const trimmedValue = value.trimStart(); 
    if (value !== trimmedValue) {
      control.setValue(trimmedValue);
    }
  }
  ngOnInit() {
    this._router.navigate([`card-update`]);
  }
}
