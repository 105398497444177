import { DomainDetailsModel } from './../models/domains/domain-details-model';
import { AddpioneerRequest, UserType } from './../models/users/user-model';
import { AreaModel, LocationModel } from './../models/other/location/location-model';
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { PaginationModel } from "../models/pagination";
import { OfferModel } from "../models";
import { OfferPostModel } from "../models/offers/offer-post-model";
import { QueryModel } from "../models/query/queryModel";
import { UserModel } from "../models/users/user-model";
import { CompanyModel } from '../models/companies/company-model';
import { SupplierConfig, SupplierModel } from '../models/supplier/supplier-model';
import { UserInvitationModel } from '../models/users/user-invitation-model';
import { MazayaEcardAdminUpdate } from '../models/mazaya-plus/membership-details-model';
import { Searchsupplier } from '../models/company-configuration/supplier-search';
import { CheckDateAvailabilityModel, CreateVoucherModel, RQeligibleDatesModel, RQGetVoucherModel, RqModifyAcademyScanLimitModel, UpdateAcademyScanlimitModel, ValidateVoucherUsersModel } from '../models/mazaya-plus/voucher-model';
import { SearchsFaq } from '../models/faq/faq-search-model';
import { easyReedem } from '../models/company-configuration/supplier';

@Injectable({
  providedIn: "root",
})
export class AdminService {
  private resource = `${environment.serverUrl}/administration`;
  private erthResource = `${environment.serverUrl}/MazayaErthVoucher/Bookings`;
  private announcementResource=`${environment.serverUrl}/mazayaAnnouncement`;
  private faqResource=`${environment.serverUrl}/mazayaFaq`;
  private voucherresource = `${environment.serverUrl}/administration/voucherreports`;
  private transactionresource=`${environment.serverUrl}/administration/transactionreports`;
  private supplierResource = `${environment.serverUrl}/MazayaSupplierConfig`;
  constructor(private http: HttpClient) { }

  // Users

  private resourceUsers = `${environment.serverUrl}/users`;

  getUsersP(queryModel: QueryModel): Observable<PaginationModel<UserModel>> {
    const url = `${this.resourceUsers}/page/${queryModel.page}`;
    return this.http.post<any>(url, queryModel);
  }

  getUser(id: number): Observable<UserModel> {
    const url = `${this.resourceUsers}/${id}`;
    return this.http.get<UserModel>(url);
  }
  getLogData(id: number): Observable<any> {
    const url = `${environment.serverUrl}/log/offerData/` + id
    return this.http.get<any>(url);

  }
  createUser(user: UserModel): Observable<any> {
    return this.http.post<any>(this.resourceUsers, user);
  }

  sendAnnouncement(announcement: any): Observable<any> {
    const url = `${this.resource}/announcement`;
    return this.http.post<any>(url, announcement);
  }
 faqCreate(faq: any): Observable<any> {
    const url = `${this.faqResource}/createFaq`;
    return this.http.post<any>(url, faq);
  }
  faqUpdate(faq: any): Observable<any> {
    const url = `${this.faqResource}/updateFaq`;
    return this.http.post<any>(url, faq);
  }
  sendnewAnnouncement(announcement: any): Observable<any> {
    const url = `${this.announcementResource}/announcement`;
    return this.http.post<any>(url, announcement);
  }

  deleteUser(id: string) {
    const url = `${this.resourceUsers}/${id}`;
    return this.http.delete<any>(url);
  }

  existsUser(email: string) {
    const url = `${this.resourceUsers}/userexists/${email}`;
    return this.http.get<any>(url);
  }

  getAllUserInvitationsP(queryModel: QueryModel): Observable<PaginationModel<UserInvitationModel>> {
    const url = `${this.resource}/invited-users`;
    return this.http.post<any>(url, queryModel);
  }

  changeUserType(username: string, newType: string): Observable<any> {
    const user = {
      username,
      newType
    };

    return this.http.post<any>(`${this.resource}/change-user-type`, user);
  }

  editECard(eCard: any): Observable<any> {
    return this.http.post<any>(`${this.resource}/ecard`, eCard);
  }

  deleteUserInvitation(username: string) {
    return this.http.delete<any>(`${this.resource}/invited-user/${username}`);
  }

  deactivateSupplier(id: number): Observable<any> {
    return this.http.delete<any>(`${this.supplierResource}/deactivatingsupplier?supplierId=${id}`);
  }
  
  activateSupplier(id: number,supplierConfig:SupplierConfig): Observable<any> {
    return this.http.post<any>(`${this.supplierResource}/activatesupplier?supplierId=${id}`,supplierConfig);
  }
  insertUserInvitation(users: UserInvitationModel[]) {
    return this.http.post<any>(`${this.resource}/insert-into-invitation-table`, users);
  }

  deleteSelfInvited(): Observable<any> {
    return this.http.delete<any>(`${this.resource}/self-invited`);
  }

  getUserTypes(): Observable<any> {
    return this.http.get<any>(`${this.resource}/get-user-domains`);
  }
  getFaq(page:number): Observable<any> {
    return this.http.get<any>(`${this.faqResource}/getAllFaq?pageNumber=${page} `);
  }
  getBuyerFaq(page:number): Observable<any> {
    return this.http.get<any>(`${this.faqResource}/getFaqBuyer?pageNumber=${page} `);
  }
  getSuppilerFaq(page:number): Observable<any> {
    return this.http.get<any>(`${this.faqResource}/getFaqSupplier?pageNumber=${page} `);
  }
  deleteFaq(id:number): Observable<any> {
    return this.http.delete<any>(`${this.faqResource}/${id} `);
  }
  getUserTypesBuyer(): Observable<UserType[]> {
    return this.http.get<UserType[]>(`${this.resource}/get-user-mazayacompanies`);
  }
  getDomain(id: number): Observable<DomainDetailsModel> {
    return this.http.get<DomainDetailsModel>(`${this.resource}/get-user-domains/${id}`);
  }
  getAllDomain(): Observable<any> {
    return this.http.get<any>(`${this.resource}/get-user-domains`);
  }

  deleteUserType(id: string) {
    const url = `${this.resource}/user-domain/${id}`;
    return this.http.delete<any>(url);
  }

  // Suppliers

  private resourceSuppliers = `${environment.serverUrl}/administration/suppliers`;

  getSuppliersP(queryModel: QueryModel): Observable<PaginationModel<UserModel>> {
    const url = `${this.resourceSuppliers}/page/${queryModel.page}`;
    return this.http.post<any>(url, queryModel);
  }

  getSupplier(id: number): Observable<UserModel> {
    const url = `${this.resourceSuppliers}/${id}`;
    return this.http.get<UserModel>(url);
  }

  createSupplier(supplier: UserModel): Observable<any> {
    return this.http.post<any>(this.resourceSuppliers, supplier);
  }

  deleteSupplier(id: string) {
    const url = `${this.resourceSuppliers}/${id}`;
    return this.http.delete<any>(url);
  }

  getSuppliersPendingP(): Observable<CompanyModel> {
    const url = `${this.resource}/suppliers-pending`;
    return this.http.get<any>(url);
  }

  approveOrRejectSuppliers(
    companyId: string,
    approved: boolean
  ): Observable<CompanyModel> {
    let ret = {
      companyId: companyId,
      approved: approved,
    };
    const url = `${this.resource}/supplier-approve-reject`;
    return this.http.post<any>(url, ret);
  }

  //Location

  getAllDefaultLocations(): Observable<LocationModel[]> {
    const url = `${this.resource}/locations`;
    return this.http.get<any>(url);
  }

  getAllDefaultAreas(): Observable<AreaModel[]> {
    const url = `${this.resource}/areas`;
    return this.http.get<any>(url);
  }

  getDefaultAreaById(areaId: number): Observable<AreaModel> {
    const url = `${this.resource}/areas/${areaId}`;
    return this.http.get<any>(url);
  }

  createDefaultArea(area: AreaModel): Observable<AreaModel> {
    const url = `${this.resource}/areas`;
    return this.http.post<AreaModel>(url, area);
  }

  updateDefaultArea(area: AreaModel): Observable<AreaModel> {
    const url = `${this.resource}/areas/${area.id}`;
    return this.http.put<any>(url, area);
  }

  deleteDefaultArea(areaId: number): Observable<any> {
    const url = `${this.resource}/areas/${areaId}`;
    return this.http.delete<any>(url);
  }

  // Domain
  createUserDomain(domain: DomainDetailsModel) {
    return this.http.post<any>(`${this.resource}/add-user-domain`, domain);
  }

  updatedUserDomain(domain: DomainDetailsModel) {
    return this.http.post<any>(`${this.resource}/update-user-domain`, domain);
  }
  //
  getLastDataSyncUserCount(date: string): Observable<number> {
    const url = `${this.resource}/lastDataSynchronization/${date}`;
    return this.http.get<number>(url);
  }

  // Inspect
  checkUserRoles(username: string): Observable<any> {
    const url = `${this.resource}/get-user-roles/${username}`;
    return this.http.get<any>(url);
  }

  // Push notification
  pushNotification(notification: { Title: string, Message: string }): Observable<any> {
    notification.Message = this.encodeString(notification.Message);
    notification.Title = this.encodeString(notification.Title);
    return this.http.post<any>(`${this.resource}/offers-send-push-notifications`, notification);
  }

  generateReport(type): Observable<any[]> {
    return this.http.get<any[]>(`${this.resource}/reports/${type}`);
  }
  generatevoucherReport(type): Observable<any[]> {
    return this.http.get<any[]>(`${this.resource}/voucherreports/${type}`);
  }

  generateSupplierReport(startDate, endDate): Observable<any[]> {
    return this.http.get<any[]>(`${this.resource}/reports/supplier-report/${startDate}/${endDate}`);
  }
  generateSSOusersReport(startDate, endDate): Observable<any[]> {

    return this.http.get<any[]>(`${this.resource}/ssouserreports/${startDate}/${endDate}`);
  }
  generateErthReport(startDate, endDate): Observable<any[]> {
  
    return this.http.get<any[]>(`${this.erthResource}/Reports/${startDate}/${endDate}`);

  }
  generateRedemptionReport(startDate, endDate): Observable<any[]> {
    return this.http.get<any[]>(`${this.voucherresource}/redeemptionvouchers/${startDate}/${endDate}`);
  }
  generatetransactionReport(startDate, endDate): Observable<any[]> {
    return this.http.get<any[]>(`${this.transactionresource}/mazaya-transaction/${startDate}/${endDate}`);
  }
  generatefamilyReport(startDate, endDate): Observable<any[]> {
    return this.http.get<any[]>(`${this.transactionresource}/mazaya-familymember/${startDate}/${endDate}`);
  }
  generatesalesReport(startDate, endDate): Observable<any[]> {
    return this.http.get<any[]>(`${this.transactionresource}/mazaya-sales/${startDate}/${endDate}`);
  }
  generateUnusedReport(startDate, endDate): Observable<any[]> {
    return this.http.get<any[]>(`${this.voucherresource}/unusedvouchers/${startDate}/${endDate}`);
  }
  generateQRcodeReport(startDate, endDate): Observable<any[]> {
    return this.http.get<any[]>(`${this.voucherresource}/qrcodereportadmin/${startDate}/${endDate}`);
  }

  getSupplierConfig(id: number): Observable<any> {
    return this.http.get<any>(`${this.supplierResource}/getsupplierusingsupplierid?supplierId=${id}`);
  }
  getSuppliers(page: number): Observable<any> {
    return this.http.get<any>(`${this.supplierResource}/getallsuppliers?pageNumber=${page}`);
  }
  // searchSuppliers(name: string, emailId: string, page: number): Observable<any> {
  //   const url = `${this.supplierResource}/searchsupplier?name=${name}&emailId=${emailId}&pageNumber=${page}`;
  //   return this.http.get<any>(url);
  // }
  searchSuppliers(searchData: Searchsupplier): Observable<any> {
    const url = `${this.supplierResource}/searchsupplier`;
    return this.http.post<any>(url, searchData);
  }
  easyRedeem(redeemData: easyReedem): Observable<any> {
    const url = `${this.supplierResource}/suppliereasyredeem`;
    return this.http.post<any>(url, redeemData);
  }
  searchFaq(searchData: SearchsFaq): Observable<any> {
    const url = `${this.faqResource}/searchFAQAdmin`;
    return this.http.post<any>(url, searchData);
  }
  private encodeString(baseString: string): string {
    return btoa(unescape(encodeURIComponent(baseString)));
  }

  getCountersForLogin(): Observable<any> {
    return this.http.get(`${this.resource}/logincounter`);
  }

  getPaymentTypes(): Observable<any> {
    return this.http.get(`${environment.serverUrl}/MazayaEcard/paymentreport`);
  }
  getPaymentReportWithFilter(from: string, to: string, status: string): Observable<any> {
    return this.http.get(`${environment.serverUrl}/MazayaEcard/paymentreport?fromdate=${from}&todate=${to}&payStatus=${status}`);
  }
  getEcardList(email: string): Observable<any> {
    return this.http.post(`${environment.serverUrl}/MazayaEcard/ecardlist?mailid=${email}`, null);
  }
  getMazayaCard(rqBody:{username: string, cardnumber: string, name: string}): Observable<any> {
    return this.http.post(`${environment.serverUrl}/MazayaEcard/searchecardlist`,rqBody);
  }
  updateEcard(eCartDetails: MazayaEcardAdminUpdate): Observable<any> {
    return this.http.post(`${environment.serverUrl}/MazayaEcard/ecardsupportupdate`, eCartDetails);
  }
  resendInvite(emailId: string): Observable<any> {
    return this.http.post<any>(`${environment.serverUrl}/MazayaEcard/resendinvitation?mail_id=${emailId}`, null);
  }
  uploadBulkData(formData: FormData): Observable<any> {

    return this.http.post<any>(`${environment.serverUrl}/MazayaEcard/aluminibulkregistration`, formData);
  }

  pioneerRegister(requestBody: AddpioneerRequest): Observable<any> {
    return this.http.post(`${environment.serverUrl}/MazayaEcard/alumini`, requestBody);
  }

  private resourceMazayaPlus = `${environment.serverUrl}/MazayaCompany`;

  private resourcePaidCard = `${environment.serverUrl}/PaidMaincardUsers`;

  generatePartialPaymentReport(startDate, endDate): Observable<any[]> {
    return this.http.get<any[]>(`${this.resourceMazayaPlus}/getpartialpaymentreport?startDate=${startDate}&endDate=${endDate}`);
  }

  generateCampanyMainCardReport(startDate, endDate): Observable<any[]> {
    return this.http.get<any[]>(`${this.resourcePaidCard}/paidcardreport?startDate=${startDate}&endDate=${endDate}`);
  }
  getMazayaCompanyList() {
    return this.http.get(`${environment.serverUrl}/MazayaCompany/getallmazayacompany`)
  }
  getSuppilerList() {
    return this.http.get(`${environment.serverUrl}/MazayaAnnouncement/getAllSuppliers`)
  }

  createorupdatemazayasupplier(formData: FormData): Observable<any> {
    return this.http.post(`${environment.serverUrl}/MazayaSupplierConfig/createorupdatemazayasupplier`, formData);
  }
  getSupplierById(id: string) {
    return this.http.get(`${environment.serverUrl}/MazayaSupplierConfig/getsupplierusingsupplierid?supplierId=${id}`)
  }
  getQuestionById(id: string) {
    return this.http.get(`${environment.serverUrl}/mazayaFaq/getFaqById?id=${id}`)
  }
  getActiveVoucherById(rqBody:RQGetVoucherModel) {
    return this.http.post(`${environment.serverUrl}/mazayaVouchers/getActiveVouchersForWeb`,rqBody)
  }
  getPastVoucherById(rqBody:RQGetVoucherModel) {
    return this.http.post(`${environment.serverUrl}/mazayaVouchers/getPastVoucherForWeb`,rqBody)
  }
  setMarkAsFaultyCode(rqBody: { MazayaVoucherUserId: number }) {
    return this.http.post(`${environment.serverUrl}/mazayaVouchers/markAsFaultyCode`, rqBody);
  }
  cancelBooking(rqBody: { MazayaVoucherUserId: number, Reason: string }) {
    return this.http.post(`${environment.serverUrl}/mazayaVouchers/cancelVoucherWeb`, rqBody);
  }
  getRequestVouchersDetails(rqBody: any) {
    return this.http.post(`${environment.serverUrl}/mazayaVouchers/GetRequestVouchersDetailsWeb`,rqBody)
  }
  getEligibleDates(requestBody: RQeligibleDatesModel) {
    return this.http.post(`${environment.serverUrl}/mazayaVouchers/getEligibleDates`, requestBody);
  }
  getEligibleUsers(reqBody:any) {
    return this.http.post(`${environment.serverUrl}/mazayaVouchers/ecardmembersWeb`,reqBody)
  }
  validateVoucherUsers(requestBody: ValidateVoucherUsersModel[]) {
    return this.http.post(`${environment.serverUrl}/mazayaVouchers/validateVoucherUsersWeb`, requestBody);
  }
  createVoucher(requestBody: CreateVoucherModel) {
    return this.http.post(`${environment.serverUrl}/mazayaVouchers/CreateOrUpdateWeb`, requestBody);
  }
  checkDateAvailableOrNot(requestBody: CheckDateAvailabilityModel) {
    return this.http.post(`${environment.serverUrl}/QRCode/unsuccessfullBookingByUsers`, requestBody);
  }
  getAllNoShow(pageIndex: number) {
    return this.http.get(`${environment.serverUrl}/MazayaVoucherSuspension/getallnoshowforsupportadmin/${pageIndex}`);
  }
  removeSuspension(rqBody:{emailId :string,supplierId :number,ecarddetailsId:number}) {
    return this.http.post(`${environment.serverUrl}/MazayaVoucherSuspension/removeSuspension`,rqBody);
  }
  getremoveSuspension(id:number) {
    return this.http.get(`${environment.serverUrl}/MazayaErthVoucher/removeSuspension/${id}`);
  }
  searchNoShow(requestBody: { emailId: string, pageNumber: number }) {
    return this.http.post(`${environment.serverUrl}/MazayaVoucherSuspension/searchnoshow`, requestBody);
  }
  searchEarthNoShow(requestBody: { emailId: string, pageNumber: number }) {
    return this.http.post(`${environment.serverUrl}/MazayaErthVoucher/getSuspendedList`, requestBody);
  }
  voucherLog(rqBody:any) {
    return this.http.post(`${environment.serverUrl}/mazayaVouchers/searchSupportAdminVouchers`,rqBody);
  }
  updateQrScanlimit(rqBody: { ecarddetailsid: number, supplierclaimlimitforQR: number, limit: number }) {
    return this.http.post(`${environment.serverUrl}/MazayaEcard/updatelimitecard`,rqBody);
  }

  getActiveVoucherByIdAdmin(rqBody:RQGetVoucherModel) {
    return this.http.post(`${environment.serverUrl}/mazayaVouchers/getActiveVouchersForWebAdmin`,rqBody)
  }
  getPastVoucherByIdAdmin(rqBody:RQGetVoucherModel) {
    return this.http.post(`${environment.serverUrl}/mazayaVouchers/getPastVoucherForWebAdmin`,rqBody)
  }
  getRequestVouchersDetailsAdmin(rqBody: any) {
    return this.http.post(`${environment.serverUrl}/mazayaVouchers/GetRequestVouchersDetailsWebAdmin`,rqBody)
  }
  getEligibleDatesAdmin(requestBody: RQeligibleDatesModel) {
    return this.http.post(`${environment.serverUrl}/mazayaVouchers/getEligibleDatesWebAdmin`, requestBody);
  }
  validateVoucherUsersAdmin(requestBody: ValidateVoucherUsersModel[]) {
    return this.http.post(`${environment.serverUrl}/mazayaVouchers/validateVoucherUsersWebAdmin`, requestBody);
  }
  createVoucherAdmin(requestBody: CreateVoucherModel) {
    return this.http.post(`${environment.serverUrl}/mazayaVouchers/CreateOrUpdateWebAdmin`, requestBody);
  }

  getalleligiblesuppliers(pageIndex: number) {
    return this.http.get(`${environment.serverUrl}/MazayaAcademyScanLimit/getalleligiblesuppliers/${pageIndex}`)
  }

  searchSupplierForAcademy(requestBody: { SupplierName: string,EmailId:string, PageNumber: number }) {
    return this.http.post(`${environment.serverUrl}/MazayaAcademyScanLimit/searchsuppliers`, requestBody);
  }
  getAllCompaniesBySupplier(id:number) {
    return this.http.get(`${environment.serverUrl}/MazayaSupplierConfig/getallcompaniesBysupplier?supplierId=${id}`);
  }
  getSupplierAcademyScanLimit(id:number){
    return this.http.get(`${environment.serverUrl}/MazayaAcademyScanLimit/${id}`);
  }
  createSupplierAcademyScanLimit(rqBody:RqModifyAcademyScanLimitModel[]){
    return this.http.post(`${environment.serverUrl}/MazayaAcademyScanLimit`,rqBody);
  }
  
  getSupplierForAcademyScanLimit(rqBody:{ecarddetailsId:number,ecardmainId:number}){
    return this.http.post(`${environment.serverUrl}/MazayaAcademyScanLimit/supplierslist`,rqBody);
  }
  updateAcademyScanLimitForVip(rqBody:UpdateAcademyScanlimitModel[]){
    return this.http.post(`${environment.serverUrl}/MazayaAcademyScanLimit/scanlimitecardforusers`,rqBody);
  }
  getMazayaEcarddetailsById(id:string){
    return this.http.get(`${environment.serverUrl}/mazayaEcard/getMazayaEcarddetailsById?ecardDetailsId=${id}`);
  }
}
