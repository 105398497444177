<div class="domains-container" id="invitations" *ngIf="!userCardView">
    <div class="header">
        <div class="title-box">
            <div class="title">Mazaya Card Update</div>
            <div class="sub-title">Please search using email</div>
        </div>
    </div>
    <div class="grid-container">
        <div class="f-select-simple">
            <input class="input-email" type="text" placeholder="Enter Email/Mobile number" [(ngModel)]="email">
        </div>
        <div class="f-select-simple">
            <input class="input-email" type="text" placeholder="Enter Card No" [(ngModel)]="cardNo">
        </div>
        <div class="f-select-simple">
            <input class="input-email" type="text" placeholder="Enter Name" [(ngModel)]="name">
        </div>
        <button class="btn btn-blue" (click)="onSearch()">
            Search
        </button>

    </div>
    <div class="n-container">
    

        <table>
            <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Card No.</th>
                <th>Relation</th>
                <th>Date of birth</th>
                <th></th>
            </tr>
            <tr *ngFor="let item of membershipDetails; let i=index">
                <td>{{item.firstname}} {{item.lastname}}</td>
                <td>{{item.email}}</td>
                <td>{{item.card_number}}</td>
                <td>{{item.relation}}</td>
                <td>{{item.dob}}</td>
                <td class="buttons">
                    <div>
                        <button class="tooltip-button" (click)="onEdit(i)">
                            <img src="../../../../assets/images/mazayaPlus/edit.svg" class="icon">
                            <span class="tooltip-text">Edit Card</span>
                        </button>
    
                        <button class="tooltip-button" *ngIf="item.relation=='Myself' || item.relation=='myself'"
                            (click)="onViewCardList(i)">
                            <img src="../../../../assets/images/mazayaPlus/eye.svg" class="icon">
                            <span class="tooltip-text">View Card</span>
                        </button>
                        <button class="tooltip-button" (click)="viewVoucher(item.id,item.userId,item.email,item.gender,item.isAdultMember)">
                            <img src="../../../../assets/images/mazayaPlus/ticket.svg" class="icon">
                            <span class="tooltip-text">View Voucher</span>
                        </button>
                        <button class="tooltip-button" *ngIf="item.claimLimitForQR!==0" (click)="increaseQrLimit(item)">
                            <img src="../../../../assets/images/mazayaPlus/qrcode.svg" class="icon">
                            <span class="tooltip-text">Increase QR code limit</span>
                        </button>
                        <button class="tooltip-button" *ngIf="item.claimLimitForQR!==0" (click)="increaseAcademyLimit(item)">
                            <img src="../../../../assets/images/icons/academy.png" class="icon">
                            <span class="tooltip-text">Increase academy scan limit</span>
                        </button>

                    </div>
                </td>
            </tr>


        </table>
    </div>

</div>

<div class="modal-container" modal *ngIf="isEnableModal">
    <div class="modal modal-width-l">
        <div class="modal-header">Modify Card</div>

        <div class="modal-content">
            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">

                        <div class="modal-body">
                            <div style="display: flex;">
                                <div class="">
                                    <div class="modify-img">
                                        <div class="file-upload">
                                            <img class="upload-image" src="assets/images/default/user_def.svg" alt=""
                                                *ngIf="!profile_image">
                                            <img class="upload-image" *ngIf="profile_image" [src]="profile_image"
                                                alt="profile">

                                            <!-- <img class="upload-image"> -->

                                        </div>
                                        <!-- <input type="file" accept=".jpg, .jpeg, .png" #fileInput style="display: none" (change)="onFileSelected($event)"> -->

                                        <input type="file" #fileInput style="display: none" accept=".jpg, .jpeg, .png"
                                            (change)="onFileSelected($event)">
                                        <button class="btn-blue" (click)="selectFile()">Change Photo</button>
                                    </div>
                                    <div class="error-validate">

                                    </div>
                                    <div class="resend-invite"
                                        *ngIf="membershipDetails[selectedCard].relation!='Myself'">
                                        <Button class="btn-blue"
                                            (click)="resendInvite(membershipDetails[selectedCard].email)">Resend
                                            Invitation </Button>
                                    </div>

                                </div>
                                <form #form [formGroup]="myForm">
                                    <div>
                                        <div>
                                            <fieldset>
                                                <legend>Member </legend>

                                                <div class="row-details">
                                                    <div class="column-details mr-16">
                                                        <div class="box">
                                                            <mat-form-field class="textbox-firstname">
                                                                <mat-label>First name</mat-label>
                                                                <input matInput formControlName="firstname" />
                                                                <mat-error
                                                                    *ngIf="myForm.get('firstname').hasError('required')">
                                                                    First name is required.
                                                                </mat-error>
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                    <div class="column-details">
                                                        <div class="box">
                                                            <mat-form-field class="textbox-lastname">
                                                                <mat-label>Last name</mat-label>
                                                                <input matInput formControlName="lastname" />
                                                                <mat-error
                                                                    *ngIf="myForm.get('lastname').hasError('required')">
                                                                    Last name is required.
                                                                </mat-error>
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="box">
                                                    <mat-form-field class="textbox-email">
                                                        <mat-label>Email</mat-label>
                                                        <input matInput formControlName="email"
                                                            [readonly]="membershipDetails[selectedCard].relation=='Myself' || membershipDetails[selectedCard].relation=='myself'" />
                                                        <mat-error *ngIf="myForm.get('email').hasError('required')">
                                                            Email is required.
                                                        </mat-error>
                                                        <mat-error *ngIf="myForm.get('email').hasError('email')">
                                                            Invalid email format.
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>

                                                <div class="row-details">
                                                    <div class="column-details mr-16"
                                                        *ngIf="membershipDetails[selectedCard].type==='A'">
                                                        <div class="box">
                                                            <mat-form-field class="calender">
                                                                <mat-label>Date of birth</mat-label>
                                                                <input matInput [matDatepicker]="picker" [max]="maxDate"
                                                                    formControlName="dob" (click)="picker.open()"
                                                                    [readonly]="true">
                                                                <mat-datepicker-toggle matIconSuffix
                                                                    [for]="picker"></mat-datepicker-toggle>
                                                                <mat-datepicker #picker></mat-datepicker>
                                                                <mat-error>
                                                                    Date of birth is required.
                                                                </mat-error>
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                    <div class="column-details mr-16"
                                                        *ngIf="membershipDetails[selectedCard].type==='C'">
                                                        <div class="box">
                                                            <mat-form-field class="calender">
                                                                <mat-label>Date of birth</mat-label>
                                                                <input matInput [matDatepicker]="picker" [max]="today"
                                                                    [min]="maxDate" formControlName="dob"
                                                                    (click)="picker.open()" [readonly]="true">
                                                                <mat-datepicker-toggle matIconSuffix
                                                                    [for]="picker"></mat-datepicker-toggle>
                                                                <mat-datepicker #picker></mat-datepicker>
                                                                <mat-error>
                                                                    Date of birth is required.
                                                                </mat-error>
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                    <div class="column-details">
                                                        <div class="box"
                                                            *ngIf="membershipDetails[selectedCard].relation!='Myself'">
                                                            <mat-form-field class="relation">
                                                                <mat-label>Relation</mat-label>
                                                                <mat-select formControlName="relation">
                                                                    <mat-option *ngFor="let relation of relations"
                                                                        [value]="relation.value">
                                                                        {{relation.viewValue}}
                                                                    </mat-option>
                                                                </mat-select>

                                                                <mat-error
                                                                    *ngIf="myForm.get('relation').hasError('required')">
                                                                    Relationship is required.
                                                                </mat-error>
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="box"
                                                            *ngIf="membershipDetails[selectedCard].relation=='Myself' || membershipDetails[selectedCard].relation=='myself'">
                                                            <mat-form-field class="relation">
                                                                <mat-label>Relation</mat-label>
                                                                <input matInput formControlName="relation"
                                                                    [readonly]="true">

                                                            </mat-form-field>

                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="box">
                                                    <div class="gender">
                                                        <div>Gender</div>
                                                        <mat-radio-group formControlName="userGender">
                                                            <mat-radio-button value="Male">Male</mat-radio-button>
                                                            <mat-radio-button value="Female">Female</mat-radio-button>
                                                        </mat-radio-group>
                                                    </div>
                                                    <div class="error-validate"
                                                        *ngIf="myForm.get('userGender').hasError('required')">
                                                        Gender is required.
                                                    </div>
                                                </div>
                                                <!-- <div class="box">
                                                    <mat-form-field class="relation">
                                                        <mat-label>Status</mat-label>
                                                        <mat-select formControlName="relation">
                                                            <mat-option value="Active">
                                                                Active
                                                            </mat-option>
                                                            <mat-option value="Inactive">
                                                                Inactive
                                                            </mat-option>

                                                        </mat-select>
                                                
                                                    </mat-form-field>
                                                </div> -->
                                            </fieldset>
                                            <!-- <button type="button" class="update-btn" (click)="onSubmit()">Save changes</button> -->
                                        </div>
                                    </div>
                                </form>


                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button class="btn btn-blue" >Save Changes</button>
            <button class="btn-grey" (click)="onCloseEdit()">Close</button>
        </div>
    </div>
</div>

<div class="domains-container" id="invitations" *ngIf="userCardView">
    <!-- <app-user-cards [selectedEmail]="selectedEmail" (goBack)="goBack()"></app-user-cards> -->

</div>

<div class="modal-container" modal *ngIf="increaseLimitModal">
    <div class="modal modal-width-l">
        <div class="modal-header">Increase the QR Scan Limit</div>

        <div class="modal-content">
            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">

                        <div class="modal-body">
                            <div class="qr-content">


                                <div class="qr-company-limit">
                                    <div class="qr-company-limit-text">
                                        QR scan limit <br /> <span>(Based on company configuration)</span>
                                    </div>
                                    <div *ngIf="selectedItem.claimLimitForQR!=1">
                                        : {{selectedItem.companyclaimLimitCount}} /
                                        {{findLimitType(selectedItem.claimLimitForQR)}}
                                    </div>
                                    <div *ngIf="selectedItem.claimLimitForQR===1">
                                        : {{findLimitType(selectedItem.claimLimitForQR)}}
                                    </div>
                                </div>

                                <div style="display: flex; justify-content: center; margin-top: 15px;"
                                    *ngIf="selectedItem.claimLimitForQR !==1"><button class="add-more-btn"
                                        (click)="addMore()" *ngIf="!isHideAddMore">Add More</button></div>

                                <div class="quantity" *ngIf="isVisibleCounter">
                                    <a (click)="decreaseLimit()" class="quantity__minus"><span>-</span></a>
                                    <input name="quantity" type="text" class="quantity__input" [ngModel]="limitCount" readonly>
                                    <a (click)="increaseLimit()" class="quantity__plus"><span>+</span></a>
                                </div>

                            </div>
                        </div>
                        <div style="display: flex;justify-content: center;">
                            <button style="margin-right: 10px;" class="btn btn-blue"
                                *ngIf="selectedItem.claimLimitForQR !==1" (click)="updateQrScanLimit()">Save</button>
                            <button class="btn-grey" (click)="closeLimitModal()">Close</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<div class="modal-container" modal *ngIf="increaseAcademyLimitModal">
    <div class="modal modal-width-m">
        <div class="modal-header">Increase Academy Scan Limit</div>

        <div class="modal-content">
           <!-- <app-vip-academy-limit [academySupplier]="academySupplier" [isAcademySave]="isAcademySave" (academyCallback)="academyCallback($event)">

           </app-vip-academy-limit> -->
        </div>
        <div style="display: flex;justify-content: center; margin: 15px;">
            <button style="margin-right: 10px;" class="btn btn-blue" (click)="saveAcademicLimit()">Save</button>
            <button class="btn-grey" (click)="increaseAcademyLimitModal=false">Close</button>
        </div>
    </div>
</div>